/* eslint-disable eqeqeq */
import { toast } from 'react-toastify';
import { GetCatalogLastUpdate, loadGifts } from './LegacyService';
import PouchDB from 'pouchdb';
import { ErrorMessages } from '../components/ErrorMessages';

export const loadFromServer = async ({ state, dispatch }) => {
  dispatch({
    type: 'SET_GIFTS',
    data: [],
  });
  //load from server
  try {
    let resp = await loadGifts(
      state.ecard.company && state.ecard.company.id ? state.ecard.company.id : '',
      0,
      8,
      state.ecard.is_gift_box == 1 ? state.ecard.vouchertypeid : null,
      state.ecard.country || 'SG'
    );
    if (resp.status === 1) {
      // console.log('response version 3', resp);

      // record category name on localstorage
      try {
        let tagsProductCategory = resp.data.tags.products_restructured
          .filter((i) => i.id === 'gift-category')[0]
          .tags.filter((i) => i.search_filter === '1')
          .map((i) => ({
            id: i.id,
            name: i.name,
            items: i.tags ? i.tags.map((t) => t.id) : [],
          }));
        localStorage.setItem('tags-product-category', JSON.stringify(tagsProductCategory));
      } catch {
        localStorage.setItem('tags-product-category', '[]');
      }

      try {
        let tagsProductType = resp.data.tags.products_restructured
          .filter((i) => i.id === 'to-whom')[0]
          .tags.filter((i) => i.search_filter === '1')
          .map((i) => ({
            id: i.id,
            name: i.name,
            items: i.tags ? i.tags.map((t) => t.id) : [],
          }));
        localStorage.setItem('tags-product-to-whom', JSON.stringify(tagsProductType));
        let tagsMerchantCategory = Object.keys(resp.data.tags.merchants.category.items).map((k) => {
          let item = resp.data.tags.merchants.category.items[k];
          return { id: k, name: item.extrasecond || item.text };
        });
        localStorage.setItem('tags-merchant-category', JSON.stringify(tagsMerchantCategory));
      } catch {
        localStorage.setItem('tags-merchant-category', '[]');
      }

      // show gift only lower than balance
      let allMerchantTags = {};
      Object.keys(resp.data.tags.merchants).forEach((k) => {
        allMerchantTags = {
          ...allMerchantTags,
          ...resp.data.tags.merchants[k].items,
        };
      });
      let resGifts = resp.data.products.map((gift) => {
        let primary_delivery = 'email';
        let primary_extrafirst = 0;
        // check have email
        let haveEmail = false;
        for (let dy of gift.dymethods) {
          if (dy.value === 'email') {
            haveEmail = true;
          }
        }
        // if not have email
        if (!haveEmail) {
          for (let dy of gift.dymethods) {
            if (dy.value === 'mail') {
              primary_delivery = 'mail';
              // primary_extrafirst = dy.extrafirst
              break;
            } else if (dy.value === 'courier' && primary_delivery === 'email') {
              primary_delivery = 'courier';
              // primary_extrafirst = dy.extrafirst
            }
          }
        }

        return {
          ...gift,
          primary_delivery,
          primary_extrafirst,
          tags_v2: gift.tags_v2.map((t) => resp.data.tags.products[t]).filter((t) => t),
          merchant: {
            ...resp.data.merchants[gift.merchant_id],
            tags: resp.data.merchants[gift.merchant_id].tags
              .map((t) => allMerchantTags[t])
              .filter((t) => t),
          },
        };
      });

      //remove giftano card  and capitaland
      resGifts = resGifts.filter((item) => {
        // b2c and new capitaland
        if (!state.ecard?.company?.id && item.merchant.id === 'MCVIZ1D72C') {
          return false;
        }
        if (
          item.merchant.id === 'MCMDYGDWF9' || // giftano card
          item.merchant.id === 'MCEFP9A7T3' || // capitaland
          item.self_fulfillment == 1
        ) {
          return false;
        }
        return true;
      });
      dispatch({
        type: 'SET_GIFTS',
        data: resGifts,
      });
      dispatch({
        type: 'SET_GIFTS_STATUS',
        data: 'success',
      });

      localStorage.setItem('catalog-last-update', Math.floor(Date.now() / 1000));

      //save to local db
      await SaveSearchResult(state.ecard.company.id, resGifts);
    } else {
      toast(resp.message || ErrorMessages.error_get_gifts, {
        type: 'warning',
      });
    }
  } catch (err) {
    console.error(err);
    toast(ErrorMessages.error_get_gifts, { type: 'error' });
  }
};

const SaveSearchResult = async (corporateId, gifts) => {
  gifts = gifts.map((i) => ({ ...i, _id: i.id }));
  try {
    let db = new PouchDB('redemption-gift-result-' + corporateId);
    await db.bulkDocs(gifts);
  } catch {
    // error save search result
  }
};

const GetSearchResult = async (corporateId) => {
  try {
    let db = new PouchDB('redemption-gift-result-' + corporateId);
    let allDocs = await db.allDocs({ include_docs: true });
    return allDocs.rows.map((i) => i.doc);
  } catch {
    return [];
  }
};

export const loadCatalog = async ({ state, dispatch }) => {
  //get last update from server;
  dispatch({ type: 'SET_GIFTS_STATUS', data: 'loading' });
  const lastResp = await GetCatalogLastUpdate(state.ecard.company.id || '', state.ecard.country);
  const lastUpdate = lastResp && lastResp.unix ? lastResp.unix : 0;

  let lastUpdateCatalog = parseInt(localStorage.getItem('catalog-last-update'));
  if (!lastUpdateCatalog || !lastUpdate) {
    await loadFromServer({ state, dispatch });
  } else {
    if (lastUpdate > lastUpdateCatalog) {
      await loadFromServer({ state, dispatch });
    } else {
      if (state.giftsStatus === 'reload') {
        dispatch({
          type: 'SET_GIFTS_STATUS',
          data: 'success',
        });
      } else {
        let savedGifts = await GetSearchResult(state.ecard.company.id);
        // console.log('prev saved gifts', savedGifts);
        if (savedGifts.length === 0) {
          await loadFromServer({ state, dispatch });
        } else {
          dispatch({
            type: 'SET_GIFTS',
            data: savedGifts,
          });
          dispatch({
            type: 'SET_GIFTS_STATUS',
            data: 'success',
          });
        }
      }
    }
  }
};
